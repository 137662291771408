<template>
  <div>
    <Header />
    <div class="privacy">
      <div class="privacy-top breadcrumbs d-flex align-items-center">
        <div
          class="container position-relative d-flex flex-column align-items-center"
        ></div>
      </div>
      <!-- <img src="../../../assets/images/info/privacy-us.jpg" alt="" /> -->
    </div>
    <b-container>
      <b-row>
        <b-col sm="12" md="12">
          <div class="about-block1">
            <div class="container text-center">
              <h1 class="privacy-title h1">
                {{ $t("privacy_policy.privacy_policy") }}
              </h1>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="privacy-block1-img">
        <b-col sm="12" md="12" class="privacy-block1-col">
          <div class="privacy-block1-info container">
            <p class="lead">{{ $t("privacy_policy.privacy_main") }}</p>
            <ol class="ml-2">
              <li>
                <strong> {{ $t("privacy_policy.privacy_label1") }}: </strong>
                <div class="inner_list">
                  <ul class="mt-1">
                    <li>
                      <strong
                        >1.1 {{ $t("privacy_policy.privacy_label1_1") }}:
                      </strong>
                      {{ $t("privacy_policy.privacy_p1_1") }}
                    </li>
                    <li>
                      <strong
                        >1.2 {{ $t("privacy_policy.privacy_label1_2") }}:
                      </strong>
                      {{ $t("privacy_policy.privacy_p1_2") }}
                    </li>
                    <li>
                      <strong
                        >1.3 {{ $t("privacy_policy.privacy_label1_3") }}:
                      </strong>
                      {{ $t("privacy_policy.privacy_p1_3") }}
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <strong> {{ $t("privacy_policy.privacy_label2") }}: </strong>
                <div class="inner_list">
                  <ul class="mt-1">
                    <li>
                      <strong
                        >2.1 {{ $t("privacy_policy.privacy_label2_1") }}:
                      </strong>
                      {{ $t("privacy_policy.privacy_p2_1") }}
                    </li>
                    <li>
                      <strong
                        >2.2 {{ $t("privacy_policy.privacy_label2_2") }}:
                      </strong>
                      {{ $t("privacy_policy.privacy_p2_2") }}
                    </li>
                    <li>
                      <strong
                        >2.3 {{ $t("privacy_policy.privacy_label2_3") }}:
                      </strong>
                      {{ $t("privacy_policy.privacy_p2_3") }}
                    </li>
                    <li>
                      <strong
                        >2.4 {{ $t("privacy_policy.privacy_label2_4") }}:
                      </strong>
                      {{ $t("privacy_policy.privacy_p2_4") }}
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <strong> {{ $t("privacy_policy.privacy_label3") }}: </strong>
                <div class="inner_list">
                  <ul class="mt-1">
                    <li>
                      <strong
                        >3.1 {{ $t("privacy_policy.privacy_label3_1") }}:
                      </strong>
                      {{ $t("privacy_policy.privacy_p3_1") }}
                    </li>
                    <li>
                      <strong
                        >3.2 {{ $t("privacy_policy.privacy_label3_2") }}:
                      </strong>
                      {{ $t("privacy_policy.privacy_p3_2") }}
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <strong> {{ $t("privacy_policy.privacy_label4") }}: </strong>
                <div class="inner_list">
                  <ul class="mt-1">
                    <li>
                      <strong>4.1 </strong>
                      {{ $t("privacy_policy.privacy_p4_1") }}
                    </li>
                    <li>
                      <strong>4.2 </strong>
                      {{ $t("privacy_policy.privacy_p4_2") }}
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <strong> {{ $t("privacy_policy.privacy_label5") }}: </strong>
                {{ $t("privacy_policy.privacy_p5") }}
              </li>
              <li>
                <strong> {{ $t("privacy_policy.privacy_label6") }}: </strong>
                {{ $t("privacy_policy.privacy_p6") }}
              </li>
              <li>
                <strong> {{ $t("privacy_policy.privacy_label7") }}: </strong>
                {{ $t("privacy_policy.privacy_p7") }}
              </li>
              <li>
                <strong> {{ $t("privacy_policy.privacy_label8") }}: </strong>
                {{ $t("privacy_policy.privacy_p8") }}
              </li>
              <li>
                <strong> {{ $t("privacy_policy.privacy_label9") }}: </strong>
                {{ $t("privacy_policy.privacy_p9") }}
                <a href="mailto: $t('privacy_policy.privacy_email')">{{
                  $t("privacy_policy.privacy_email")
                }}</a>
              </li>
            </ol>
          </div>
        </b-col>
      </b-row>
      <b-row class="privacy-block1-img">
        <b-col sm="12" md="12" class="privacy-block1-col">
          <div class="privacy-block1-info container">
            <p class="lead">{{ $t("privacy_policy.privacy_footer") }}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Header from "@/layouts/landpage/HeaderHome.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { BFormInput } from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
};
</script>

<style scoped>
.privacy {
  /* margin-top: 110px; */
  margin-bottom: 75px;

  /* padding: 40px 40px 128px 40px; */
}
.privacy-block1-col {
  margin: auto;
  padding-bottom: 50px;
}

.privacy .contact-top {
  height: 290px;
}
.privacy .privacy-block1-img img {
  height: 300px;
}
.privacy .privacy-block1-info {
  margin-top: 50px;
}
.privacy .privacy-block1-info h2 {
  font-size: 25px;
  color: #005692;
}
.privacy-title {
  font-size: 40px;
  font-weight: 700;
  color: #366ce3;
  margin-bottom: 30px;
}
.privacy .privacy-block1-info p {
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
}
.privacy-block-footer {
  padding: 0 50px;
}
@media (max-width: 550px) {
  .privacy .privacy-top img {
    width: 330px;
  }
  .privacy .privacy-block1-img img {
    display: none;
  }
  .privacy .privacy-block1-info p {
    text-align: start;
  }
}

.privacy-block2 {
  margin-top: 60px;
}
.privacy-block2 h2 {
  text-align: center;
  font-weight: 500;
  color: #005692;
  margin-bottom: 45px;
}

[dir] .card-img,
[dir] .card-img-top {
  height: 250px;
}
[dir] .card .card-title {
  color: #366ce3;
  font-weight: 500;
  cursor: pointer;
}
[dir] .card-text:last-child {
  font-size: 14px;
}
@media (max-width: 550px) {
  .block-cvshot .card {
    position: relative;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    align-content: flex-start;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .block-cvshot img {
    margin: 0 7px;
    width: 350px;
  }
}
@media (min-width: 920px) {
  .block-cvshot .card .card-title {
    color: #366ce3;
    font-weight: 600;
    font-size: 22px;
  }
  .block-cvshot .card-text:last-child {
    font-size: 15px;
  }
}
@media (min-width: 920px) {
  [dir="ltr"] .card-body {
    padding: 1.5rem;
    min-height: 350px;
  }
  .cvshots-card .card-body {
    min-height: auto;
    margin: auto;
  }
}
.card-text:last-child {
  font-size: 16px;
  font-weight: 500;
}

.privacy-top {
  background-image: url(../../../assets/images/info/privacy_policy.png);
  padding: 140px 0 60px 0;
  min-height: 60vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.breadcrumbs:before {
  content: "";
  background-color: rgba(27, 47, 69, 0.7);
  position: absolute;
  inset: 0;
}
.breadcrumbs h2 {
  font-size: 56px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.inner_list ul {
  list-style: none;
  padding: 0;
}
.privacy-block1-info ol {
  padding: 0;
}

.privacy-block1-info ol li,
.inner_list ul li {
  padding: 0 0 15px 26px;
  position: relative;
  font-size: 15px;
}
.privacy-block1-info ol li i,
.inner_list ul li i {
  font-size: 16px;
  color: #56b8e6;
}
.blockchainImg {
  width: 450px;
}
@media (max-width: 550px) {
  .blockchainImg {
    display: none;
    /* width: 400px; */
  }
  .privacy-block2 h2 {
    font-size: 18px;
  }
}
</style>
<style scoped>
@media (min-width: 920px) {
  [dir="rtl"] .card-body {
    padding: 1.5rem;
    min-height: 250px;
  }
}
</style>
